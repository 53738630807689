import React from 'react';
import css from './SectionArchitectInfo.module.css';
import { allOptions } from '../../../util/general';

const camelCaseToRegularSpacing = str => {
  return str.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase();
};

const capitalize = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const SectionArchitectInfo = ({ hide = true, publicData = {}, infoNotAvailable = true }) => {
  if (hide) return null;
  if (infoNotAvailable) {
    return (
      <div className={css.wrapper}>
        <p className={css.title}>Project info</p>
        <p className={css.subtitle}>
          Information will be available after submitting a cost estimate request
        </p>
      </div>
    );
  }
  const keys = Object.keys(publicData);
  const data = [];
  keys
    .filter(key => !['userType', 'terms', 'mbSupport', 'profileImage'].includes(key))
    .forEach(key => {
      if (Array.isArray(publicData[key])) {
        let composedLabel = '';
        publicData[key].forEach((i, index) => {
          composedLabel +=
            index === publicData[key].length - 1 ? allOptions[i] : `${allOptions[i]}, `;
        });
        data.push({
          label: capitalize(camelCaseToRegularSpacing(key)),
          valueLabel: composedLabel,
        });
      } else {
        data.push({
          label: capitalize(camelCaseToRegularSpacing(key)),
          valueLabel: allOptions[publicData[key]] || publicData[key],
        });
      }
    });

  return (
    <div className={css.wrapper}>
      <p className={css.title}>Project info</p>
      {data
        .filter(i => !!i)
        .map(({ label, valueLabel }) => {
          return (
            <div className={css.row}>
              <div className={css.rowLabel}>{label}:</div>
              <div className={css.rowValue}>{valueLabel}</div>
            </div>
          );
        })}
    </div>
  );
};

export default SectionArchitectInfo;
