// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { post } from './api';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { removeUndefinedValues } from './general';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = typeof window !== 'undefined' && getAnalytics(app);
// Initialize Cloud Storage and get a reference to the service
export const storage = getStorage(app);
// Create a storage reference from our storage service
export const storageRef = ref(storage);

// DB
export const firestore = getFirestore();
export const addToFirestoreDoc = async (collectionId, documentId, docData) => {
  try {
    const refDoc = doc(firestore, `${collectionId}/${documentId}`);
    const filteredData = removeUndefinedValues(docData);
    return setDoc(refDoc, filteredData);
  } catch (e) {
    throw e;
  }
};

export const uploadFileToFirebase = async ({ fileName, file, folderName = '', metadata = {} }) => {
  try {
    const fileRef = ref(storage, `${folderName}${fileName}`);
    const uploadedFile = await uploadBytes(fileRef, file, metadata);
    return uploadedFile;
  } catch (e) {
    throw e;
  }
};

export const downloadFileFromFirebase = async ({
  fileName,
  folderName = '',
  transactionId = '',
}) => {
  try {
    if (typeof window !== 'undefined') {
      const downloadUrl = await getDownloadURL(ref(storage, `${folderName}${fileName}`));
      // const xhr = new XMLHttpRequest();
      // xhr.responseType = 'blob';
      // xhr.onload = event => {
      // const blob = xhr.response;
      // Create a link element, use it to download the blob, and then remove it
      // const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = downloadUrl;
      a.target = '_blank';
      // Use the file name as the download name
      a.download = fileName.replace(`${transactionId}_`, '');
      document.body.appendChild(a);
      a.click();
      // window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      // };
      // xhr.open('GET', downloadUrl);
      // xhr.send();
    }
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getFileDownloadURL = async ({ folderName, fileName }) => {
  try {
    if (typeof window !== 'undefined') {
      const downloadUrl = await getDownloadURL(ref(storage, `${folderName}${fileName}`));
      return downloadUrl;
    }
  } catch (e) {
    throw e;
  }
};
