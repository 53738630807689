import React, { useEffect, useState } from 'react';
import css from './HouseBuilderInfoForm.module.css';
import Button, { PrimaryButton } from '../../Button/Button';
import Modal from '../../Modal/Modal';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import FieldTextInput from '../../FieldTextInput/FieldTextInput';
import arrayMutators from 'final-form-arrays';
import Form from '../../Form/Form';
import FieldPhoneNumberInput from '../../FieldPhoneNumberInput/FieldPhoneNumberInput';
import FieldCheckboxGroup from '../../FieldCheckboxGroup/FieldCheckboxGroup';
import {
  planningPermissionOptions,
  siteGradientOptions,
  yesNoOptions,
} from '../../../util/general';
import { addToFirestoreDoc } from '../../../util/firebase';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateProfile } from '../../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';

const HouseBuilderInfoFormComponent = ({
  currentListing,
  currentUser,
  history,
  onUpdateProfile,
  disabled,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [isOpenAlreadyInquiredModal, setOpenAlreadyInquiredModal] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);
  }, [isOpen]);

  if (!currentListing?.id?.uuid) return null;

  if (!currentUser?.id?.uuid) {
    return (
      <div className={css.wrapper}>
        <Button type="button" onClick={() => history.push('/login')}>
          Send an enquiry
        </Button>
      </div>
    );
  }

  const {
    hasSite,
    siteDescription,
    siteGradient,
    siteLocation,
    planningPermissionObtained,
    phoneNumber,
  } = currentUser.attributes.profile.publicData || {};

  const initialValues = {
    hasSite,
    siteDescription,
    siteGradient,
    siteLocation,
    planningPermissionObtained,
    phoneNumber,
  };
  const userCurrHBInquiries = currentUser.attributes.profile.publicData.userCurrHBInquiries || [];
  const alreadyInquired = userCurrHBInquiries.find(id => id === currentListing.id.uuid);

  const handleFormSubmit = values => {
    const documentId = `${currentUser.id.uuid}-${currentListing.id.uuid}`;
    //TODO move the DB path to env
    return addToFirestoreDoc('house-builder-inquiries', documentId, {
      ...values,
      listingId: currentListing.id.uuid,
      listingTitle: currentListing.attributes.title,
      customerId: currentUser.id.uuid,
      customerEmail: currentUser.attributes.email,
      submitDate: new Date().toString(),
      inquiryType: 'house_builder',
    })
      .then(resp => {
        return onUpdateProfile({
          publicData: {
            userCurrHBInquiries: [...userCurrHBInquiries, currentListing.id.uuid],
          },
        }).catch(e => {
          console.log(e);
          throw e;
        });
      })
      .then(resp => {
        setOpen(false);
        setIsSuccessModalOpen(true);
      })
      .catch(e => {
        console.log(e);
        setError(true);
      });
  };

  const handleOpenModal = () => {
    if (alreadyInquired) {
      setOpenAlreadyInquiredModal(true);
    } else {
      setOpen(true);
    }
  };

  return (
    <div className={css.wrapper}>
      <Button
        type="button"
        // onClick={handleOpenModal}
        onClick={e => {
          e.preventDefault();
          history.push(`/profile-settings?requestedListing=${currentListing?.id?.uuid}`);
        }}
        disabled={disabled}
      >
        Send an enquiry
      </Button>
      <Modal
        id="AlreadyInquiredModal"
        isOpen={isOpenAlreadyInquiredModal}
        onClose={() => setOpenAlreadyInquiredModal(false)}
        onManageDisableScrolling={() => {}}
      >
        <center>
          <h4>
            You already sent an enquiry for this listing.
            <br />
            We'll get back with an answer for you soon.
          </h4>
        </center>
      </Modal>

      <Modal
        id="AlreadyInquiredModal"
        isOpen={isSuccessModalOpen}
        onClose={() => setIsSuccessModalOpen(false)}
        onManageDisableScrolling={() => {}}
      >
        <center>
          <h4>
            Your enquiry has been submitted successfully!
            <br />
            We'll get back with an answer for you soon.
          </h4>
        </center>
      </Modal>
      <Modal
        id="AuthorInquiryModal"
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        onManageDisableScrolling={() => {}}
      >
        <div className={css.formWrapper}>
          <FinalForm
            mutators={{ ...arrayMutators }}
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
            render={fieldRenderProps => {
              const { handleSubmit, values } = fieldRenderProps;

              const submitInProgress = false;
              const submitDisabled = false;

              return (
                <Form className={css.formWrapper} onSubmit={handleSubmit}>
                  <FieldCheckboxGroup
                    className={css.field}
                    name="hasSite"
                    id="hasSite"
                    label={'Do you own a site?'}
                    // optionLabelClassName={css.finePrint}
                    options={[
                      ...yesNoOptions,
                      { key: 'currentlyLooking', label: 'Currently looking' },
                    ]}
                    type="radio"
                  />

                  {values.hasSite === 'yes' && (
                    <>
                      <FieldTextInput
                        className={css.field}
                        type="text"
                        id={'siteDescription'}
                        name="siteDescription"
                        label={'Site Description including previous use'}
                        placeholder={'Type here...'}
                      />

                      <FieldCheckboxGroup
                        className={css.field}
                        name="siteGradient"
                        id="siteGradient"
                        label={'Gradient of site'}
                        // optionLabelClassName={css.finePrint}
                        options={siteGradientOptions}
                        type="radio"
                      />

                      <FieldTextInput
                        className={css.field}
                        type="text"
                        id={'siteLocation'}
                        name="siteLocation"
                        label={
                          'Location of site (Type in full address or use google maps geo reference)'
                        }
                        placeholder={'Type here...'}
                      />

                      <FieldCheckboxGroup
                        className={css.field}
                        name="planningPermissionObtained"
                        id="planningPermissionObtained"
                        label={'Is the planning permision obtained?'}
                        options={planningPermissionOptions}
                        type="radio"
                      />
                    </>
                  )}

                  <FieldTextInput
                    className={css.field}
                    type="text"
                    id={'typeOfSupportNeeded'}
                    name="typeOfSupportNeeded"
                    label={'What type of support would you like from The Modern Builder?'}
                    placeholder={'Type here...'}
                  />

                  <FieldPhoneNumberInput
                    className={css.field}
                    name="phoneNumber"
                    id={'phoneNumber'}
                    label={'Phone number'}
                    placeholder={'Type here...'}
                  />
                  {error && (
                    <p className={css.error}>Woops! Something went wrong, please try again.</p>
                  )}
                  <PrimaryButton
                    type="submit"
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                  >
                    Submit
                  </PrimaryButton>
                </Form>
              );
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const HouseBuilderInfoForm = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter
)(HouseBuilderInfoFormComponent);

export default HouseBuilderInfoForm;
